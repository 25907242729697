define('ember-cli-full-calendar/components/full-calendar', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var observer = _ember['default'].observer;
  var Component = _ember['default'].Component;

  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['full-calendar'],

    // Event Data
    events: null,

    // General Display
    headerLeft: { left: 'title', center: '', right: 'today prev,next' },
    theme: false,
    firstDay: 0,
    isRTL: false,
    weekends: true,
    hiddenDays: [],
    fixedWeekCount: true,
    weekNumbers: false,
    height: 'auto',
    eventLimit: false,
    businessHours: false,

    // Text/Time Customization
    lang: 'en',
    displayEventEnd: {
      month: false,
      basicView: false,
      agendaWeek: true,
      agendaDay: true,
      basicDay: true
    },

    // Timezone
    timezone: false,

    // Views
    defaultView: 'month',

    // Agenda Options
    allDaySlot: true,
    allDayText: 'all-day',
    maxTime: '24:00:00',
    minTime: '00:00:00',
    scrollTime: '06:00:00',
    slotDuration: '00:30:00',
    slotEventOverlap: true,
    slotLabelFormat: 'h(:mm)a',
    slotLabelInterval: '00:30:00',
    snapDuration: '00:30:00',

    // Current Date
    defaultDate: null,
    nowIndicator: false,

    // Event Rendering
    eventColor: null,
    eventBackgroundColor: null,
    eventBorderColor: null,
    eventTextColor: null,
    nextDayThreshold: "09:00:00",
    eventOrder: "title",

    // Event Dragging & Resizing
    editable: false,
    eventStartEditable: false,
    eventDurationEditable: false,
    dragRevertDuration: 500,
    dragOpacity: 0.75,
    dragScroll: true,
    dragOverlap: true,

    // Selecting
    selectable: false,

    updateEvents: observer('events.[]', function () {
      var fullCalendarElement = this.$();
      fullCalendarElement.fullCalendar('removeEvents');
      fullCalendarElement.fullCalendar('addEventSource', this.get('events'));
      fullCalendarElement.fullCalendar('rerenderEvents');
    }),

    /**
     * Register this component to parent controller. We need this to be able to send actions from outside.
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('targetObject.' + this.get('register-as'), this);
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$().fullCalendar({

        // Event Data
        events: this.get('events'),
        eventSources: this.get('eventSources'),
        timeFormat: this.get('timeFormat'),

        // General Display
        header: this.get('header'),
        customButtons: this.get('customButtons'),
        theme: this.get('theme'),
        firstDay: this.get('firstDay'),
        isRTL: this.get('isRTL'),
        weekends: this.get('weekends'),
        hiddenDays: this.get('hiddenDays'),
        fixedWeekCount: this.get('fixedWeekCount'),
        weekNumbers: this.get('weekNumbers'),
        height: this.get('height'),
        eventLimit: this.get('eventLimit'),
        defaultView: this.get('defaultView'),
        businessHours: this.get('businessHours'),
        titleFormat: this.get('titleFormat'),
        columnFormat: this.get('columnFormat'),

        viewRender: function viewRender(view, element) {
          _this.sendAction('viewRender', view, element);
        },

        // Timezone
        timezone: this.get('timezone'),
        now: this.get('now'),

        // Agenda Option
        allDaySlot: this.get('allDaySlot'),
        allDayText: this.get('allDayText'),
        maxTime: this.get('maxTime'),
        minTime: this.get('minTime'),
        scrollTime: this.get('scrollTime'),
        slotDuration: this.get('slotDuration'),
        slotEventOverlap: this.get('slotEventOverlap'),
        slotLabelFormat: this.get('slotLabelFormat'),
        slotLabelInterval: this.get('slotLabelInterval'),
        snapDuration: this.get('snapDuration'),

        // Current Date
        defaultDate: this.get('defaultDate'),

        // Event Rendering
        eventColor: this.get('eventColor'),
        eventBackgroundColor: this.get('eventBackgroundColor'),
        eventBorderColor: this.get('eventBorderColor'),
        eventTextColor: this.get('eventTextColor'),
        nextDayThreshold: this.get('nextDayThreshold'),
        eventOrder: this.get('eventOrder'),

        // Text/Time Customization
        lang: this.get('lang'),
        displayEventEnd: this.get('displayEventEnd'),

        // Clicking & Hovering
        dayClick: function dayClick(date, jsEvent, view) {
          _this.sendAction('dayClick', date, jsEvent, view);
        },

        eventClick: function eventClick(calEvent, jsEvent, view) {
          _this.sendAction('eventClick', calEvent, jsEvent, view);
        },

        eventDragStart: function eventDragStart(event, jsEvent, ui, view) {
          _this.sendAction('eventDragStart', event, jsEvent, ui, view);
        },

        eventDragStop: function eventDragStop(event, jsEvent, ui, view) {
          _this.sendAction('eventDragStop', event, jsEvent, ui, view);
        },

        eventDrop: function eventDrop(event, delta, revertFunc, jsEvent, ui, view) {
          _this.sendAction('eventDrop', event, delta, revertFunc, jsEvent, ui, view);
        },

        eventResize: function eventResize(event, delta, revertFunc, jsEvent, ui, view) {
          _this.sendAction('eventResize', event, delta, revertFunc, jsEvent, ui, view);
        },

        eventResizeStart: function eventResizeStart(event, jsEvent, ui, view) {
          _this.sendAction('eventResizeStart', event, jsEvent, ui, view);
        },

        eventResizeStop: function eventResizeStop(event, jsEvent, ui, view) {
          _this.sendAction('eventResizeStop', event, jsEvent, ui, view);
        },

        select: function select(start, end, jsEvent, view) {
          _this.sendAction('select', start, end, jsEvent, view);
        },

        eventMouseover: function eventMouseover(event, jsEvent, view) {
          _this.sendAction('eventMouseover', event, jsEvent, view);
        },

        eventMouseout: function eventMouseout(event, jsEvent, view) {
          _this.sendAction('eventMouseout', event, jsEvent, view);
        },

        // Event Rendering
        eventRender: function eventRender(event, element, view) {
          _this.sendAction('eventRender', event, element, view);
        },

        eventAfterRender: function eventAfterRender(event, element, view) {
          _this.sendAction('eventAfterRender', event, element, view);
        },

        eventAfterAllRender: function eventAfterAllRender(view) {
          _this.sendAction('eventAfterAllRender', view);
        },

        eventDestroy: function eventDestroy(event, element, view) {
          _this.sendAction('eventDestroy', event, element, view);
        },

        // Dragging & Resizing
        editable: this.get('editable'),
        eventStartEditable: this.get('eventStartEditable'),
        eventDurationEditable: this.get('eventDurationEditable'),
        dragRevertDuration: this.get('dragRevertDuration'),
        dragOpacity: this.get('dragOpacity'),
        dragScroll: this.get('dragScroll'),

        // Selecting
        selectable: this.get('selectable')
      });
    },

    // Actions sent from outsite to full calendar
    actions: {

      // Curent date
      prev: function prev() {
        this.$().fullCalendar('prev');
      },

      next: function next() {
        this.$().fullCalendar('next');
      },

      prevYear: function prevYear() {
        this.$().fullCalendar('prevYear');
      },

      nextYear: function nextYear() {
        this.$().fullCalendar('nextYear');
      },

      today: function today() {
        this.$().fullCalendar('today');
      },

      gotoDate: function gotoDate(date) {
        this.$().fullCalendar('gotoDate', date);
      },

      incrementDate: function incrementDate(duration) {
        this.$().fullCalendar('incrementDate', duration);
      },

      // Event Rendering
      renderEvent: function renderEvent(event, stick) {
        this.$().fullCalendar('renderEvent', event, stick);
      },

      rerenderEvents: function rerenderEvents() {
        this.$().fullCalendar('rerenderEvents');
      },

      refetchEvents: function refetchEvents() {
        this.$().fullCalendar('refetchEvents');
      }
    }
  });
});